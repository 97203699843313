import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Pagination, Snackbar, Alert } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../services/dataapi';

const ManageCustomIP = () => {
    const [ipList, setIPList] = useState([]); // 存储 IP 列表
    const [loading, setLoading] = useState(true); // 加载状态
    const [searchQuery, setSearchQuery] = useState(''); // 搜索关键字
    const [page, setPage] = useState(1); // 当前页数
    const [totalPages, setTotalPages] = useState(1); // 总页数
    const [editDialogOpen, setEditDialogOpen] = useState(false); // 编辑对话框状态
    const [editData, setEditData] = useState(null); // 当前正在编辑的 IP 数据
    const [syncStatus, setSyncStatus] = useState({ open: false, success: false, message: '' }); // 一键同步状态

    // 获取用户的 token
    const token = localStorage.getItem('token');

    // 初始化数据
    useEffect(() => {
        fetchIPList();
    }, [page, searchQuery]);

    // 获取 IP 列表
    const fetchIPList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/list-ips`, {
                params: { page, search: searchQuery },
                headers: { Authorization: token },
            });
            setIPList(response.data.data);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        } catch (err) {
            console.error('获取 IP 列表失败:', err);
            setLoading(false);
        }
    };

    // 一键同步操作
    const handleSync = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/copy-to-free-custom-ip`, {}, {
                headers: { Authorization: token },
            });
            setSyncStatus({ open: true, success: true, message: '同步成功' });
        } catch (err) {
            console.error('同步失败:', err);
            setSyncStatus({ open: true, success: false, message: '同步失败' });
        }
    };

    // 关闭提示框
    const handleCloseSnackbar = () => {
        setSyncStatus({ open: false, success: false, message: '' });
    };

    // 处理删除操作
    const handleDelete = async (id) => {
        if (window.confirm('确定要删除该 IP 数据吗？')) {
            try {
                await axios.delete(`${API_BASE_URL}/delete-ip/${id}`, {
                    headers: { Authorization: token },
                });
                fetchIPList(); // 重新获取列表
            } catch (err) {
                console.error('删除 IP 数据失败:', err);
            }
        }
    };

    // 打开编辑对话框
    const handleEdit = (ipData) => {
        setEditData({
            ...ipData,
            regions: Array.isArray(ipData.regions) ? ipData.regions.join(', ') : '', // 确保 regions 被转换为字符串
        });
        setEditDialogOpen(true);
    };

    // 关闭编辑对话框
    const handleEditClose = () => {
        setEditDialogOpen(false);
        setEditData(null);
    };

    // 提交编辑操作
    const handleEditSubmit = async () => {
        const updatedData = {
            ...editData,
            regions: editData.regions.split(',').map((region) => region.trim()), // 将字符串转换为数组
        };

        try {
            await axios.put(`${API_BASE_URL}/update-ip/${editData._id}`, updatedData, {
                headers: { Authorization: token },
            });
            fetchIPList(); // 更新列表
            handleEditClose(); // 关闭对话框
        } catch (err) {
            console.error('更新 IP 数据失败:', err);
        }
    };

    // 处理输入改变
    const handleInputChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 5, mb: 5, p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h4" gutterBottom>
                    管理自定义 IP 数据
                </Typography>

                {/* 搜索栏和同步按钮 */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <TextField
                        label="搜索 IP 段或国家"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ width: '800px', mr: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={fetchIPList}>
                        搜索
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleSync} sx={{ ml: 2 }}>
                        一键同步到开放API数据库
                    </Button>
                </Box>

                {/* IP 列表表格 */}
                <Table sx={{ border: '1px solid #ccc', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)' }}>
                    <TableHead sx={{ bgcolor: 'grey.100' }}>
                        <TableRow>
                            <TableCell>IP 段</TableCell>
                            <TableCell>国家</TableCell>
                            <TableCell>地区</TableCell>
                            <TableCell>AS编号</TableCell>
                            <TableCell>运营商</TableCell>
                            <TableCell>IP 类型</TableCell>
                            <TableCell>操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(ipList) && ipList.length > 0 ? (
                            ipList.map((ipData) => (
                                <TableRow key={ipData._id}>
                                    <TableCell>{ipData.ipRange}</TableCell>
                                    <TableCell>{ipData.country}</TableCell>
                                    <TableCell>{ipData.regions.join(', ')}</TableCell>
                                    <TableCell>{ipData.asNumber}</TableCell>
                                    <TableCell>{ipData.asName}</TableCell>
                                    <TableCell>{ipData.type}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(ipData)} color="primary">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(ipData._id)} color="secondary">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    暂无数据
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                {/* 分页控制 */}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        color="primary"
                        sx={{ mt: 2 }}
                    />
                </Box>

                {/* 编辑对话框 */}
                <Dialog open={editDialogOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
                    <DialogTitle>编辑 IP 数据</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="IP 段"
                                    fullWidth
                                    name="ipRange"
                                    value={editData?.ipRange || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="国家"
                                    fullWidth
                                    name="country"
                                    value={editData?.country || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="地区 (用逗号分隔)"
                                    fullWidth
                                    name="regions"
                                    value={editData?.regions || ''} // 这里已经确保 regions 是字符串
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="AS编号"
                                    fullWidth
                                    name="asNumber"
                                    value={editData?.asNumber || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="运营商"
                                    fullWidth
                                    name="asName"
                                    value={editData?.asName || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="IP 类型"
                                    fullWidth
                                    name="type"
                                    value={editData?.type || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditClose} color="secondary">
                            取消
                        </Button>
                        <Button onClick={handleEditSubmit} color="primary">
                            保存
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* 同步状态提示框 */}
                <Snackbar open={syncStatus.open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={syncStatus.success ? 'success' : 'error'}>
                        {syncStatus.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
};

export default ManageCustomIP;
